import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/var/www/kecman.dev/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const IconButton = makeShortcode("IconButton");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`contactMe`}</h1>
    <p>{`If you have any question, about my work, or a proposal feel free to contact me`}</p>
    <Link href="https://github.com/k3cman" mdxType="Link">
  <IconButton mdxType="IconButton">
  <Image src='/images/github.png' mdxType="Image" />
  </IconButton>
  </Link>
  <Link href="https://www.linkedin.com/in/k3cman/" mdxType="Link">
  <IconButton mdxType="IconButton">
  <Image src='/images/linked_in.png' mdxType="Image" />
  </IconButton>
  </Link>
  <Link href="mailto:kecman.dev@gmail.com" mdxType="Link">
  <IconButton mdxType="IconButton">
  <Image src='/images/email.png' mdxType="Image" />
  </IconButton>
  </Link>
  <br />
  <Link href="/images/cv.pdf" mdxType="Link">
  Get Resume / >
  </Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      